<!-- 문의사항 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-blue d-inline-block mb-0">접속통계</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Search -->
                  <form class="form-inline mb-2">
                    <div class="col-sm-2">
                      <preset-select
                        @change="getDataFromApi()"
                        placeholder="회원구분"
                        v-model="query.grade"
                        :choices="[
                          ['전체', '전체'],
                          ['전법회원', '전법회원'],
                          ['일반회원', '일반회원'],
                        ]"
                      />
                    </div>
                    <div class="col-sm-2">
                      <preset-select
                        @change="getDataFromApi()"
                        placeholder="지부"
                        v-model="query.local"
                        :choices="LOCAL.map((x) => [x, x])"
                      />
                    </div>
                    <div class="col-sm-2">
                      <preset-select
                        @change="getDataFromApi()"
                        placeholder="지회"
                        v-model="query.district"
                        :choices="districts.map((x) => [x, x])"
                      />
                    </div>
                    <div class="col-sm-2 mr-1">
                      <base-input
                        @change="getDataFromApi()"
                        placeholder="모둠"
                        name="name"
                        v-model="query.modum"
                        @keyup.enter.prevent="getDataFromApi()"
                      />
                    </div>
                    <div class="col-sm-2">
                      <input
                        @change="getDataFromApi()"
                        type="date"
                        class="form-control col"
                        placeholder="날짜(YYYY-MM-DD)"
                        v-model="query.createdDate"
                      />
                    </div>
                  </form>
                  <!-- End Search -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->

            <!-- List -->
            <div class="ml-2">total: {{ total }}/{{ total2 }}</div>
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="접속일"
                sortable
                min-width="80px"
                prop="createdDate"
              >
              </el-table-column>
              <el-table-column
                label="회원구분"
                sortable
                min-width="80px"
                prop="grade"
              >
              </el-table-column>

              <el-table-column
                label="소속"
                sortable
                min-width="120px"
                prop="dept"
              >
              </el-table-column>
              <el-table-column
                label="회원번호"
                sortable
                min-width="100px"
                prop="memberNo"
              >
              </el-table-column>
              <el-table-column
                label="이름"
                sortable
                min-width="100px"
                prop="name"
              >
              </el-table-column>

              <el-table-column
                label="접속횟수"
                sortable
                min-width="50px"
                prop="hit"
              >
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";
import { LOCAL, getLocalNames } from "@/consts/map";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
  },
  // 데이터 객체 추가
  data() {
    return {
      LOCAL,
      getLocalNames,
      districts: [],
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        local: "",
        district: "",
        createdDate: "",
        modum: "",
        grade: "",
      },
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      total2: 0,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      if (this.query.local) {
        this.districts = getLocalNames(this.query.local);
        if (!this.districts.includes(this.query.district)) {
          this.query.district = "";
        }
      }

      for (const field of ["local", "district", "grade"]) {
        if (
          this.query[field] === "전체" ||
          this.query[field] === "" ||
          !this.query[field]
        ) {
          this.query[field] = "";
        }
      }
      this.loading = true;

      const url = `service/myinformation/statics?page=${page}&grade=${this.query.grade}&modum=${this.query.modum}&createdDate=${this.query.createdDate}&local=${this.query.local}&district=${this.query.district}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        this.total = 0;
        if (data.rows > 0) {
          for (const row of d) {
            row.dept = row.local + "/" + row.district + "/" + row.modum;
            this.total = this.total + row.hit;
          }
        }
        this.tableData = d;
        this.total2 = data.total;
      });
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
