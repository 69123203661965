<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">손님 수정</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="form-group mt-2">
          <base-input
            type="text"
            label="이름"
            name="name"
            placeholder="이름"
            v-model="newData.name"
          />
        </div>
        <div class="form-group mt-2">
          <base-input
            type="text"
            label="전화번호"
            name="enc_phone_mobile"
            placeholder="전화번호"
            v-model="newData.enc_phone_mobile"
          />
        </div>
        <div class="form-group mt-2">
          <base-input
            type="number"
            label="회원번호"
            name="mem_id"
            placeholder="회원번호"
            v-model="newData.mem_id"
          />
        </div>
        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >수정 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";

export default {
  components: {
    Modal,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        place: "",
        title: "",
        mem_id: "",
      },
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    // 등록하기
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));

      api.post("service/myinformation/entry", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      data.phone_tail = data.enc_phone_mobile.substr(-4);
      api
        .put(`service/myinformation/guest2/${row.guest_id}`, { data })
        .then(() => {
          api
            .put(`service/myinformation/guest/${row.id}`, { data })
            .then(() => {
              this.$emit("editDone", row, data);
              this.hideModal();
            });
        });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.title = "";
      this.newData.place = "";
      this.newData.open_dt = "";
      this.mem_id = "";
      this.newData.valid_dt = "";
      this.newData.expire_dt = "";
      this.newData.organization = "";
      this.newData.dept_cd = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newQuestion = "";
      this.loadModal(`service/myinformation/guest/${row.id}`, row, (data) => {
        return {
          name: data.name,
          enc_phone_mobile: data.enc_phone_mobile,
          mem_id: data.mem_id,
        };
      });
    },
  },
};
</script>
