<!-- 내정보 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 title">회원 종합 정보</h3>
            </div>
          </div>
        </div>
        <div>
          <modal v-model:show="showQuestionModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">문의하기</h6>
            </template>
            <form role="form">
              <h6 class="modal-title mb-2 text-red">
                ** 정보가 누락되었거나 사실과 다른 경우 해당내용을 상세히
                작성하여 보내주시기 바랍니다.(날짜,문의종류 내용 구체적 기재)
              </h6>
              <label class="form-control-label ml-3">요청업무선택</label>
              <div class="row">
                <preset-select
                  placeholder="선택"
                  v-model="newData.questPart"
                  @change="questionPart2(newData)"
                  :choices="[
                    ['법회'],
                    ['천결'],
                    ['수련'],
                    ['삼보수호비'],
                    ['자격'],
                    ['정기활동'],
                    ['교육수련'],
                    ['기타'],
                  ]"
                />
              </div>
              <label class="form-control-label ml-3">소분류선택</label>
              <div class="row ml-0">
                <preset-select
                  placeholder="선택필수"
                  v-model="newData.questPart2"
                  :choices="questPart2"
                />
              </div>

              <div class="form-group mt-2">
                <label for="exampleFormControlTextarea1">문의내용</label>
                <textarea
                  class="form-control"
                  v-model="question"
                  rows="5"
                ></textarea>
              </div>

              <div class="text-center">
                <base-button type="primary" class="my-4" @click="onQuestion"
                  >보내기</base-button
                >
                <base-button
                  type="secondary"
                  class="my-4"
                  @click="showQuestionModal = false"
                  >닫 기</base-button
                >
              </div>
            </form>
          </modal>
        </div>
        <div>
          <modal v-model:show="showDharmaModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">법회이력</h6>
            </template>
            <form class="form-inline mb-2">
              <div>연간출석현황</div>
              <div class="ml-3">
                <preset-select
                  @change="dharma()"
                  placeholder="연도"
                  v-model="query.dharmaDate"
                  :choices="[[presentYear], [lastYear]]"
                />
              </div>
            </form>
            <el-table
              class="table-responsive table"
              :data="tableData2"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="법회유형"
                sortable
                min-width="100px"
                prop="dharmaPart"
              >
              </el-table-column>
              <el-table-column
                label="총출석"
                sortable
                min-width="100px"
                prop="dharmaAttend"
              >
              </el-table-column>
            </el-table>
            <br />
            <form class="form-inline mb-2">
              <div>법회참석내역</div>
              <div class="ml-3">
                <preset-select
                  @change="dharma()"
                  placeholder=""
                  v-model="query.dharmaType"
                  :choices="[['전체'], ['수행법회'], ['전법활동가법회']]"
                />
              </div>
            </form>
            <br />
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'bbmDate', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="법회유형"
                sortable
                min-width="100px"
                prop="bbmType"
              >
              </el-table-column>
              <el-table-column
                label="진행일자"
                sortable
                min-width="100px"
                prop="bbmDate"
              >
              </el-table-column>

              <el-table-column
                label="출석"
                sortable
                min-width="100px"
                prop="bbmAttend"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('법회')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div>
          <modal v-model:show="showTrinatnaModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title mb-2 text-blue">삼보수호비이력</h6>
            </template>

            <!-- <el-table
              class="table-responsive table"
              :data="tableData2"
              :default-sort="{ prop: 'triDate', order: 'ascending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="약정개시일"
                min-width="100px"
                prop="triBegin"
              >
              </el-table-column>
              <el-table-column
                label="약정액"
                min-width="100px"
                prop="triAmount"
              >
              </el-table-column>
              <el-table-column
                label="납부방식"
                min-width="100px"
                prop="triMethod"
              >
              </el-table-column>
              <el-table-column
                label="총납부횟수"
                min-width="100px"
                prop="triCount"
              >
              </el-table-column>
            </el-table> -->

            <!-- <br /><br /> -->
            <form class="form-inline mb-2">
              <div>연간 월 납부현황</div>
              <div class="ml-3">
                <preset-select
                  @change="triratna()"
                  placeholder="연도"
                  v-model="query.dharmaDate"
                  :choices="[[presentYear], [lastYear]]"
                />
              </div>
            </form>
            <el-table
              class="table-responsive table"
              :data="tableData3"
              :default-sort="{ prop: 'triDate', order: 'ascending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="1월" min-width="100px" prop="triJan">
              </el-table-column>
              <el-table-column label="2월" min-width="100px" prop="triFeb">
              </el-table-column>
              <el-table-column label="3월" min-width="100px" prop="triMar">
              </el-table-column>
              <el-table-column label="4월" min-width="100px" prop="triApr">
              </el-table-column>
              <el-table-column label="5월" min-width="100px" prop="triMay">
              </el-table-column>
              <el-table-column label="6월" min-width="100px" prop="triJun">
              </el-table-column>
            </el-table>
            <el-table
              class="table-responsive table"
              :data="tableData3"
              :default-sort="{ prop: 'triDate', order: 'ascending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="7월" min-width="100px" prop="triJul">
              </el-table-column>
              <el-table-column label="8월" min-width="100px" prop="triAug">
              </el-table-column>
              <el-table-column label="9월" min-width="100px" prop="triSep">
              </el-table-column>
              <el-table-column label="10월" min-width="100px" prop="triOct">
              </el-table-column>
              <el-table-column label="11월" min-width="100px" prop="triNov">
              </el-table-column>
              <el-table-column label="12월" min-width="100px" prop="triDec">
              </el-table-column>
            </el-table>
            <br />
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'triDate', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="날짜"
                sortable
                min-width="100px"
                prop="triDate"
              >
              </el-table-column>
              <el-table-column
                label="납부방법"
                sortable
                min-width="100px"
                prop="triMethod"
              >
              </el-table-column>

              <el-table-column
                label="금액"
                sortable
                min-width="100px"
                prop="triAmount"
              >
              </el-table-column>
              <el-table-column
                label="납부지회"
                sortable
                min-width="100px"
                prop="triBbdg"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('삼보수호비')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>

        <div>
          <modal v-model:show="showPracticeModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">수련 상세 이력</h6>
            </template>
            <form class="form-inline mb-0">
              <div>최초 수련 정보</div>
            </form>
            <el-table
              class="table-responsive table"
              :data="tableData2"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="수련구분"
                sortable
                min-width="100px"
                prop="trType"
              >
              </el-table-column>
              <el-table-column
                label="총횟수"
                sortable
                min-width="100px"
                prop="trAttCnt"
              >
              </el-table-column>

              <el-table-column
                label="최초차수"
                sortable
                min-width="100px"
                prop="trFirstSeq"
              >
              </el-table-column>

              <el-table-column
                label="최초입재일"
                sortable
                min-width="100px"
                prop="trFirstDate"
              >
              </el-table-column>
            </el-table>

            <form class="form-inline mt-4">
              <div>상세 수련 이력</div>
            </form>
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'trDate', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="입재일"
                sortable
                min-width="100px"
                prop="trDate"
              >
              </el-table-column>
              <el-table-column
                label="수련구분"
                sortable
                min-width="100px"
                prop="trType"
              >
              </el-table-column>
              <el-table-column
                label="차수"
                sortable
                min-width="100px"
                prop="trSeq"
              >
              </el-table-column>

              <el-table-column
                label="안내자"
                sortable
                min-width="100px"
                prop="trGuide"
              >
              </el-table-column>

              <el-table-column
                label="수련장"
                sortable
                min-width="100px"
                prop="trPlace"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('수련')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div>
          <modal v-model:show="showQualificationModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">자격 심사 상세 정보</h6>
            </template>
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'qualDate', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="심사기준일"
                sortable
                min-width="100px"
                prop="qualDate"
              >
              </el-table-column>
              <el-table-column
                label="회원구분"
                sortable
                min-width="100px"
                prop="qualTargetGrade"
              >
              </el-table-column>
              <el-table-column
                label="심사유형"
                sortable
                min-width="100px"
                prop="qualType"
              >
              </el-table-column>

              <el-table-column
                label="승인일"
                sortable
                min-width="100px"
                prop="qualApproveDate"
              >
              </el-table-column>
              <el-table-column
                label="결과회원구분"
                sortable
                min-width="100px"
                prop="qualResultGrade"
              >
              </el-table-column>
              <el-table-column
                label="결과계위"
                sortable
                min-width="100px"
                prop="qualResultLevel"
              >
              </el-table-column>
              <el-table-column
                label="결과상태"
                sortable
                min-width="100px"
                prop="qualState"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('자격')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div>
          <modal v-model:show="showAssociationModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">천일결사</h6>
            </template>
            <el-table
              class="table-responsive table"
              :data="tableData2"
              :default-sort="{ prop: 'asoStartDt', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="구분"
                sortable
                min-width="250px"
                prop="asoPart"
              >
              </el-table-column>
              <el-table-column
                label="차수"
                sortable
                min-width="150px"
                prop="asoSeq"
              >
              </el-table-column>
              <el-table-column
                label="날짜"
                sortable
                min-width="150px"
                prop="asoDate"
              >
              </el-table-column>
            </el-table>
            <form class="form-inline mb-2 mt-4">
              <div>상세 입재 정보</div>
            </form>

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'asoStartDt', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="만일"
                sortable
                min-width="50px"
                prop="asoManil"
              >
              </el-table-column>

              <el-table-column
                label="입재일"
                sortable
                min-width="100px"
                prop="asoStartDt"
              >
              </el-table-column>
              <el-table-column
                label="입재식"
                sortable
                min-width="80px"
                prop="asoAttendYn"
              >
              </el-table-column>
              <el-table-column
                label="회향일"
                sortable
                min-width="100px"
                prop="asoReturnDt"
              >
              </el-table-column>
              <el-table-column
                label="결사금"
                sortable
                min-width="80px"
                prop="asoOfferingYn"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('천결')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div>
          <modal v-model:show="showServiceModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title text-blue">활동</h6>
            </template>

            <form class="form-inline mb-2">
              <div>현재활동현황</div>
            </form>
            <el-table
              class="table-responsive table"
              :data="tableData2"
              :default-sort="{ prop: 'srvAppointDt', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="만일"
                sortable
                min-width="60px"
                prop="srvManil"
              >
              </el-table-column>
              <el-table-column
                label="천일"
                sortable
                min-width="60px"
                prop="srvChunil"
              >
              </el-table-column>

              <el-table-column
                label="부서"
                sortable
                min-width="100px"
                prop="srvDept"
              >
              </el-table-column>

              <el-table-column
                label="소임"
                sortable
                min-width="100px"
                prop="srvPosition"
              >
              </el-table-column>
              <el-table-column
                label="상세소임"
                sortable
                min-width="100px"
                prop="srvDuty"
              >
              </el-table-column>
              <el-table-column
                label="상태"
                sortable
                min-width="100px"
                prop="srvState"
              >
              </el-table-column>
              <el-table-column
                label="개시일"
                sortable
                min-width="100px"
                prop="srvAppointDt"
              >
              </el-table-column>
              <el-table-column
                label="회향일"
                sortable
                min-width="100px"
                prop="srvReturnDt"
              >
              </el-table-column>
            </el-table>

            <br />
            <form class="form-inline mb-2">
              <div>활동이력</div>
            </form>

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'srvAppointDt', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="만일"
                sortable
                min-width="60px"
                prop="srvManil"
              >
              </el-table-column>
              <el-table-column
                label="천일"
                sortable
                min-width="60px"
                prop="srvChunil"
              >
              </el-table-column>

              <el-table-column
                label="부서"
                sortable
                min-width="100px"
                prop="srvDept"
              >
              </el-table-column>

              <el-table-column
                label="소임"
                sortable
                min-width="100px"
                prop="srvPosition"
              >
              </el-table-column>
              <el-table-column
                label="상세소임"
                sortable
                min-width="100px"
                prop="srvDuty"
              >
              </el-table-column>
              <el-table-column
                label="상태"
                sortable
                min-width="100px"
                prop="srvState"
              >
              </el-table-column>
              <el-table-column
                label="개시일"
                sortable
                min-width="100px"
                prop="srvAppointDt"
              >
              </el-table-column>
              <el-table-column
                label="회향일"
                sortable
                min-width="100px"
                prop="srvReturnDt"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('정기활동')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div>
          <modal v-model:show="showEducationModal" size="lg">
            <template v-slot:header>
              <h6 class="modal-title">
                <h6 class="modal-title text-blue">교육수련</h6>
              </h6>
            </template>
            <form class="form-inline mb-2">
              <div>연도</div>
              <div class="ml-3">
                <preset-select
                  @change="education()"
                  placeholder="연도"
                  v-model="query.eduDate"
                  :choices="[[presentYear], [lastYear]]"
                />
              </div>
            </form>

            <br />
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'eduDate', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="교육일자"
                sortable
                min-width="120px"
                prop="eduDate"
              >
              </el-table-column>
              <el-table-column label="대분류" min-width="100px" prop="eduType">
              </el-table-column>
              <el-table-column
                label="소분류"
                min-width="100px"
                prop="eduDetail"
              >
              </el-table-column>

              <el-table-column
                label="교육명"
                min-width="200px"
                prop="eduSubject"
              >
              </el-table-column>
              <el-table-column label="총수업" min-width="70px" prop="eduCnt">
              </el-table-column>
              <el-table-column label="출석" min-width="70px" prop="eduAtt">
              </el-table-column>

              <el-table-column
                label="수료여부"
                min-width="70px"
                prop="eduGraduatedYn"
              >
              </el-table-column>
            </el-table>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="onClose"
                >확인</base-button
              >
              <base-button
                type="success"
                class="ml-4"
                @click="questionModal('교육수련')"
                >문의하기</base-button
              >
            </div>
          </modal>
        </div>
        <div class="card-body pl-2">
          <table width="70%">
            <tr>
              <td class="left">구글계정</td>
              <td class="right" colspan="2" width="400px">{{ email }}</td>
            </tr>
            <tr>
              <td class="left">이 름/회원번호</td>
              <td class="right" colspan="2">
                {{ data.mem_name }}/{{ data.mem_id }}
              </td>
            </tr>
            <tr>
              <td class="left">회원구분</td>
              <td v-if="data.mem_state" class="right">
                {{ data.mem_grade }}/{{ data.mem_level }}/{{ data.mem_state }}
              </td>
              <td else class="right" colspan="2">
                {{ data.mem_grade }}/{{ data.mem_level }}
              </td>
            </tr>
            <tr>
              <td class="left">소속</td>
              <td class="right" colspan="2">{{ data.mem_dept }}</td>
            </tr>
            <tr>
              <td class="left">통일의병번호</td>
              <td class="right" colspan="2">
                {{ data.tongil_no }} ({{ data.tongil_state }})
              </td>
            </tr>
            <tr>
              <td class="left">불대정보</td>
              <td class="right" colspan="2">
                {{ data.bd_b_year }}년 {{ data.bd_b_term }}학기 졸업
              </td>
            </tr>
            <tr>
              <td class="left">경전대정보</td>
              <td class="right" colspan="2">
                {{ data.bd_k_year }}년 {{ data.bd_k_term }}학기 졸업
              </td>
            </tr>
            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">법회출석</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      type="primary"
                      icon
                      @click="dharma"
                      title="상세보기"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-white"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td
                class="right"
                v-if="
                  data.mem_grade === '전법회원' &&
                  !data.mem_dept.includes('국제지부')
                "
              >
                {{ presentYearTBbmAttCnt }} / {{ presentYearTBbmCnt }}
              </td>
              <td class="right" v-else>
                {{ presentYearPBbmAttCnt }} / {{ presentYearPBbmCnt }}
              </td>
            </tr>

            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">수련정보</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      outline
                      type="primary"
                      title="상세보기"
                      icon
                      @click="practice"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-primary"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td class="right">깨장 {{ data.wake_term }}차</td>
            </tr>

            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">천일결사</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      type="primary"
                      icon
                      @click="association"
                      title="상세보기"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-white"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td class="right">
                {{ data.aso_last }} / {{ data.aso_offer_last
                }}<font size="2">(최근입재식참석/결사금납부)</font>
              </td>
            </tr>
            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">삼보수호비</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      outline
                      type="primary"
                      icon
                      title="상세보기"
                      @click="triratna"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-primary"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td class="right" v-if="data.tri_last_yn === '완납'">
                {{ presentYear2 }}-{{ lastMonth }} ({{ data.tri_last_yn }})
              </td>
              <td class="right" v-else>
                {{ presentYear2 }}-{{ lastMonth }}
                <div class="text-red">({{ data.tri_last_yn }})</div>
              </td>
            </tr>
            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">자격심사</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      type="primary"
                      icon
                      @click="qualification"
                      title="상세보기"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-white"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td class="right" v-if="qualState">
                {{ qualGrade }} ({{ qualLevel }}, {{ qualState }},
                {{ qualLastDate }})
              </td>
              <td class="right" v-else>
                {{ qualGrade }} ({{ qualLevel }}, {{ qualLastDate }})
              </td>
            </tr>
            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">정기활동</div>
                  <div class="divRight">
                    <base-button
                      title="상세보기"
                      size="sm"
                      outline
                      type="primary"
                      icon
                      @click="service"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-primary"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>
              <td class="right">
                <div v-if="isDuty">{{ dept }} / {{ duty }}</div>
              </td>
            </tr>

            <tr>
              <td class="left">
                <div class="row ml-0">
                  <div class="divLeft">교육수련</div>
                  <div class="divRight">
                    <base-button
                      size="sm"
                      type="primary"
                      icon
                      @click="education"
                      title="상세보기"
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-fat-add text-white"></i
                      ></span>
                      <span class="btn-inner--text"></span>
                    </base-button>
                  </div>
                </div>
              </td>

              <td class="right"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import Modal from "@/components/Modal";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { ElTable, ElTableColumn } from "element-plus";
import moment from "moment-timezone";

export default {
  components: {
    RouteBreadCrumb,
    Modal,
    PresetSelect,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      data: {},
      email: "",
      questPart: "",
      questPart2: "",
      showDharmaModal: false,
      showPracticeModal: false,
      showAssociationModal: false,
      showTrinatnaModal: false,
      showQualificationModal: false,
      showQuestionModal: false,
      showServiceModal: false,
      showEducationModal: false,
      question: "",
      su: "",
      jeon: "",
      presentYear: 0,
      presentYear2: 0,
      lastMonth: 0,
      lastYear: 0,
      newData: {
        questPart: "",
        questPart2: "",
        question: "",
      },
      query: {
        dharmaType: "전법활동가법회",
        dharmaDate: "",
        eduDate: "",
      },
      su1: 0,
      su2: 0,
      jeon1: 0,
      jeon2: 0,
      tableData: [],
      tableData2: [],
      tableData3: [],
      qualLastDate: "",
      duty: "",
      dept: "",
      qualGrade: "",
      qualLevel: "",
      qualState: "",
      presentYearTBbmCnt: 0,
      presentYearTBbmAttCnt: 0,
      presentYearPBbmCnt: 0,
      presentYearPBbmAttCnt: 0,
      lastYearTBbmCnt: 0,
      lastYearTBbmAttCnt: 0,
      lastYearPBbmCnt: 0,
      lastYearPBbmAttCnt: 0,
      perPage: 5, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      triJan: "",
      triFeb: "",
      triMar: "",
      triApr: "",
      triMay: "",
      triJun: "",
      triJul: "",
      triAug: "",
      triSep: "",
      triOct: "",
      triNov: "",
      triDec: "",
      isDuty: false,
      isService: false,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  computed: {},
  // 함수 추가
  methods: {
    // api 호출
    questionPart2(newData) {
      if (newData.questPart == "법회") {
        this.questPart2 = [["법회출석", "법회출석"]];
      } else if (newData.questPart == "천결") {
        this.questPart2 = [
          ["입재식", "입재식"],
          ["결사금", "결사금"],
        ];
      } else if (newData.questPart == "삼보수호비") {
        this.questPart2 = [
          ["", "문의에 해당하는 년도와 달을 정확히 입력해주세요"],
        ];
      } else if (newData.questPart == "수련") {
        this.questPart2 = [
          ["깨달음의장", "깨달음의장"],
          ["나눔의장", "나눔의장"],
          ["명상", "명상"],
        ];
      } else if (newData.questPart == "정기활동") {
        this.questPart2 = [["정기활동이력", "정기활동이력"]];
      } else if (newData.questPart == "기타") {
        this.questPart2 = [["기타", "기타"]];
      }
    },
    getDataFromApi() {
      const token = api.Auth.tokenContent;
      const url2 = `service/myinformation/edu?email=${token.email}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const eduStudent = [];
        for (const member of data.data) {
          for (const student of member.groups) {
            for (const item of student.students) {
              if (eduStudent.indexOf(item) < 0) {
                eduStudent.push(item);
              }
            }
          }
        }
        if (eduStudent.includes(token.memberNo) && token.grade == "일반회원") {
          this.$router.push({
            name: "나누기",
          });
        }
      });
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (b > a) {
        this.$router.push({
          name: "note",
        });
      }
      api
        .get(`service/myinformation/member4?memberNo=${token.memberNo}`)
        .then((response5) => {
          if (!api.isSuccess(response5)) {
            return;
          }

          this.email = sessionStorage.getItem("email");
          const result = response5.data;
          this.data = result.data;

          let st = this.srvTime();
          moment.tz.setDefault("Asia/Seoul");
          let today = moment(st);
          this.presentYear = today.format("YYYY");
          this.presentYear2 = today.format("YYYY");
          this.query.dharmaDate = this.presentYear;
          this.lastYear = this.presentYear - 1;
          this.lastMonth = today.subtract(1, "months").format("M");
          if (this.lastMonth == 12) {
            this.presentYear2--;
          }
          if (this.data.tri_last_yn === "Y") {
            this.data.tri_last_yn = "완납";
          } else {
            this.data.tri_last_yn = "미납";
          }

          for (const member of this.data.service_detail) {
            if (
              member.srv_state === "정상" &&
              member.srv_main_duty === "Y" &&
              this.isService === false
            ) {
              this.duty = member.srv_duty;
              this.dept = member.srv_dept;
              this.isDuty = true;
              this.isService = true;
            }
          }

          for (const member of this.data.qualification_detail) {
            if (member.qual_approve_date) {
              this.qualLastDate = member.qual_approve_date;
              this.qualGrade = member.qual_result_grade;
              this.qualLevel = member.qual_result_level;
              this.qualState = member.qual_state;
            }
          }

          for (const member of this.data.meetings) {
            if (
              member.bbm_type === "전법활동가법회" &&
              member.bbm_year == this.presentYear
            ) {
              this.presentYearTBbmCnt = member.bbm_cnt;
              this.presentYearTBbmAttCnt = member.bbm_att_cnt;
            } else if (
              member.bbm_type === "전법활동가법회" &&
              member.bbm_year == this.lastYear
            ) {
              this.lastYearTBbmCnt = member.bbm_cnt;
              this.lastYearTBbmAttCnt = member.bbm_att_cnt;
            } else if (
              member.bbm_type === "수행법회" &&
              member.bbm_year == this.presentYear
            ) {
              this.presentYearPBbmCnt = member.bbm_cnt;
              this.presentYearPBbmAttCnt = member.bbm_att_cnt;
            } else if (
              member.bbm_type === "수행법회" &&
              member.bbm_year == this.lastYear
            ) {
              this.lastYearPBbmCnt = member.bbm_cnt;
              this.lastYearPBbmAttCnt = member.bbm_att_cnt;
            }
          }
        });
    },
    questionModal(questPart) {
      this.question = "";
      // this.questPart = questPart;
      this.newData.questPart = questPart;
      this.newData.questPart2 = "";
      this.questionPart2(this.newData);
      this.showDharmaModal = false;
      this.showAssociationModal = false;
      this.showPracticeModal = false;
      this.showTrinatnaModal = false;
      this.showQualificationModal = false;
      this.showServiceModal = false;
      this.showEducationModal = false;
      this.showQuestionModal = true;
    },
    onQuestion() {
      const url = `service/myinformation/manager?local=aa`;
      api.get(url).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;
        let teamChief = "";
        let memberChief = "";
        for (const row of d2) {
          if (row.role === "지부팀장") {
            teamChief = row.email;
          } else {
            memberChief = row.email;
          }
        }
        this.question = this.question.trim();
        if (!this.question) {
          alert("문의할 내용을 작성해주세요");
          this.showQuestionModal = true;
        } else if (this.question.length < 10) {
          alert("문의 내용은 10글자 이상 작성하셔야 등록됩니다.");
          this.showQuestionModal = true;
        } else {
          const data = {};
          data.question = this.question;
          data.questPart = this.newData.questPart;
          data.questPart2 = this.newData.questPart2;
          if (
            data.questPart === "법회" ||
            data.questPart === "수련" ||
            data.questPart === "교육수련" ||
            data.questPart === "기타"
          ) {
            data.resulter = teamChief;
          } else {
            data.resulter = memberChief;
          }
          api.post("service/myinformation/question", { data }).then(() => {
            this.$emit("addDone", data);
          });
        }
      });
      this.showQuestionModal = false;
    },
    dharma() {
      this.showDharmaModal = true; //모달창을 뜨게 해준다.
      let doc1 = [];
      let doc = {};

      if (this.query.dharmaDate == this.presentYear) {
        doc = {
          dharmaPart: "전법활동가 법회",
          dharmaAttend:
            this.presentYearTBbmAttCnt + "/" + this.presentYearTBbmCnt,
        };
        doc1.push(doc);
        doc = {
          dharmaPart: "수행법회",
          dharmaAttend:
            this.presentYearPBbmAttCnt + "/" + this.presentYearPBbmCnt,
        };
        doc1.push(doc);
      } else {
        doc = {
          dharmaPart: "전법활동가 법회",
          dharmaAttend: this.lastYearTBbmAttCnt + "/" + this.lastYearTBbmCnt,
        };
        doc1.push(doc);
        doc = {
          dharmaPart: "수행법회",
          dharmaAttend: this.lastYearPBbmAttCnt + "/" + this.lastYearPBbmCnt,
        };

        doc1.push(doc);
      }

      this.tableData2 = doc1;
      let doc2 = [];

      const token = api.Auth.tokenContent;
      api
        .get(`service/myinformation/memberMeeting?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const meetingData = response.data.data;

          for (const member of meetingData) {
            const tempYear = member.bbm_date.split("-");
            if (this.query.dharmaDate == this.presentYear) {
              if (tempYear[0] == this.presentYear) {
                if (this.query.dharmaType === "전체") {
                  const doc = {
                    bbmType: member.bbm_type,
                    bbmDate: member.bbm_date,
                    bbmAttend: member.bbm_attend_yn,
                  };
                  doc2.push(doc);
                } else if (this.query.dharmaType === "전법활동가법회") {
                  if (member.bbm_type === "전법활동가법회") {
                    const doc = {
                      bbmType: member.bbm_type,
                      bbmDate: member.bbm_date,
                      bbmAttend: member.bbm_attend_yn,
                    };
                    doc2.push(doc);
                  }
                } else {
                  if (member.bbm_type === "수행법회") {
                    const doc = {
                      bbmType: member.bbm_type,
                      bbmDate: member.bbm_date,
                      bbmAttend: member.bbm_attend_yn,
                    };
                    doc2.push(doc);
                  }
                }
              }
            } else if (this.query.dharmaDate == this.lastYear) {
              if (tempYear[0] == this.lastYear) {
                if (this.query.dharmaType === "전체") {
                  const doc = {
                    bbmType: member.bbm_type,
                    bbmDate: member.bbm_date,
                    bbmAttend: member.bbm_attend_yn,
                  };
                  doc2.push(doc);
                } else if (this.query.dharmaType === "전법활동가법회") {
                  if (member.bbm_type === "전법활동가법회") {
                    const doc = {
                      bbmType: member.bbm_type,
                      bbmDate: member.bbm_date,
                      bbmAttend: member.bbm_attend_yn,
                    };
                    doc2.push(doc);
                  }
                } else {
                  if (member.bbm_type === "수행법회") {
                    const doc = {
                      bbmType: member.bbm_type,
                      bbmDate: member.bbm_date,
                      bbmAttend: member.bbm_attend_yn,
                    };
                    doc2.push(doc);
                  }
                }
              }
            }
          }
          this.tableData = doc2;
        });
    },
    service() {
      const token = api.Auth.tokenContent;
      api
        .get(`service/myinformation/memberService?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const serviceData = response.data.data;
          this.showServiceModal = true; //모달창을 뜨게 해준다.
          let doc2 = [];
          let doc1 = [];

          for (const member of serviceData) {
            if (member.srv_state === "정상") {
              const doc = {
                srvDuty: member.srv_duty,
                srvPosition: member.srv_position,
                srvManil: member.srv_manil,
                srvChunil: member.srv_chunil,
                srvAppointDt: member.srv_appoint_dt,
                srvReturnDt: member.srv_return_dt,
                srvState: member.srv_state,
                srvDept: member.srv_dept,
              };

              doc1.push(doc);
            } else {
              const doc = {
                srvDuty: member.srv_duty,
                srvPosition: member.srv_position,
                srvManil: member.srv_manil,
                srvChunil: member.srv_chunil,
                srvAppointDt: member.srv_appoint_dt,
                srvReturnDt: member.srv_return_dt,
                srvState: member.srv_state,
                srvDept: member.srv_dept,
              };

              doc2.push(doc);
            }
          }
          this.tableData = doc2;
          this.tableData2 = doc1;
        });
    },
    qualification() {
      this.showQualificationModal = true; //모달창을 뜨게 해준다.
      let doc2 = [];
      const token = api.Auth.tokenContent;
      api
        .get(
          `service/myinformation/memberQualification?memberNo=${token.memberNo}`
        )
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const qualification = response.data.data;
          let i = qualification.length;

          for (const member of qualification) {
            const doc = {
              qualNum: i,
              qualDate: member.qual_date,
              qualType: member.qual_type,
              qualApproveDate: member.qual_approve_date,
              qualResultGrade: member.qual_result_grade,
              qualResultLevel: member.qual_result_level,
              qualState: member.qual_state,
              qualTargetGrade: member.qual_target_grade,
            };
            i--;
            doc2.push(doc);
          }
          this.tableData = doc2;
        });
    },
    association() {
      this.showAssociationModal = true; //모달창을 뜨게 해준다.

      let doc1 = [];
      let doc = {
        asoPart: "최초 예비결사차수",
        asoSeq: this.data.aso_first,
        asoDate: this.data.aso_first_date,
      };
      doc1.push(doc);
      doc = {
        asoPart: "결사차수(최초 결사금납부 차수)",
        asoSeq: this.data.aso_first_attend,
        asoDate: this.data.aso_first_attend_date,
      };

      doc1.push(doc);
      this.tableData2 = doc1;

      let doc2 = [];

      const token = api.Auth.tokenContent;
      api
        .get(
          `service/myinformation/memberAssociation?memberNo=${token.memberNo}`
        )
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const association = response.data.data;
          let i = association.length;
          for (const member of association) {
            let doc = {};
            if (member.aso_begil === 11) {
              doc = {
                asoNum: i,
                asoManil: "회향식",
                asoStartDt: member.aso_start_dt,
                asoReturnDt: member.aso_return_dt,
                asoAttendYn: member.aso_attend_yn,
                asoOfferingYn: "",
              };
            } else {
              doc = {
                asoNum: i,
                asoManil:
                  member.aso_manil +
                  "-" +
                  member.aso_chunil +
                  "-" +
                  member.aso_begil,
                asoStartDt: member.aso_start_dt,
                asoReturnDt: member.aso_return_dt,
                asoAttendYn: member.aso_attend_yn,
                asoOfferingYn: member.aso_offering_yn,
              };
            }
            i--;
            doc2.push(doc);
          }
          this.tableData = doc2;
        });
    },
    practice() {
      this.showPracticeModal = true; //모달창을 뜨게 해준다.
      let doc1 = [];
      const token = api.Auth.tokenContent;
      api
        .get(`service/myinformation/memberPractice?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const practice = response.data.data;
          for (const member of this.data.trainings) {
            const doc = {
              trType: member.tr_type,
              trAttCnt: member.tr_att_cnt,
              trFirstSeq: member.tr_first_seq,
              trFirstDate: member.tr_first_date,
            };
            doc1.push(doc);
          }
          this.tableData2 = doc1;

          let doc2 = [];
          for (const member of practice) {
            const doc = {
              trType: member.tr_type,
              trPlace: member.tr_place,
              trGuide: member.tr_guide,
              trSeq: member.tr_seq,
              trDate: member.tr_date,
            };
            doc2.push(doc);
          }
          this.tableData = doc2;
        });
    },
    triratna() {
      this.showTrinatnaModal = true; //모달창을 뜨게 해준다.
      this.triJan = "";
      this.triFeb = "";
      this.triMar = "";
      this.triApr = "";
      this.triMay = "";
      this.triJun = "";
      this.triJul = "";
      this.triAug = "";
      this.triSep = "";
      this.triOct = "";
      this.triNov = "";
      this.triDec = "";

      let doc1 = [];
      let doc = {};
      doc = {
        triBegin: this.data.tri_begin,
        triAmount: this.data.tri_amount,
        triMethod: this.data.tri_method,
        triCount: this.data.tri_count,
      };
      doc1.push(doc);

      this.tableData2 = doc1;

      let doc2 = [];
      const token = api.Auth.tokenContent;
      api
        .get(`service/myinformation/memberTriratna?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const trinatna = response.data.data;
          for (const member of trinatna) {
            if (this.query.dharmaDate == this.presentYear) {
              if (member.tri_year == this.presentYear) {
                const doc = {
                  triDate: member.tri_date,
                  triMethod: member.tri_method,
                  triAmount: member.tri_amount,
                  triBbdg: member.tri_bbdg,
                };
                doc2.push(doc);
                if (member.tri_month == 1) {
                  this.triJan = member.tri_amount;
                } else if (member.tri_month == 2) {
                  this.triFeb = member.tri_amount;
                } else if (member.tri_month == 3) {
                  this.triMar = member.tri_amount;
                } else if (member.tri_month == 4) {
                  this.triApr = member.tri_amount;
                } else if (member.tri_month == 5) {
                  this.triMay = member.tri_amount;
                } else if (member.tri_month == 6) {
                  this.triJun = member.tri_amount;
                } else if (member.tri_month == 7) {
                  this.triJul = member.tri_amount;
                } else if (member.tri_month == 8) {
                  this.triAug = member.tri_amount;
                } else if (member.tri_month == 9) {
                  this.triSep = member.tri_amount;
                } else if (member.tri_month == 10) {
                  this.triOct = member.tri_amount;
                } else if (member.tri_month == 11) {
                  this.triNov = member.tri_amount;
                } else if (member.tri_month == 12) {
                  this.triDec = member.tri_amount;
                }
              }
            } else if (this.query.dharmaDate == this.lastYear) {
              if (member.tri_year == this.lastYear) {
                const doc = {
                  triDate: member.tri_date,
                  triMethod: member.tri_method,
                  triAmount: member.tri_amount,
                  triBbdg: member.tri_bbdg,
                };
                doc2.push(doc);
                if (member.tri_month == 1) {
                  this.triJan = member.tri_amount;
                } else if (member.tri_month == 2) {
                  this.triFeb = member.tri_amount;
                } else if (member.tri_month == 3) {
                  this.triMar = member.tri_amount;
                } else if (member.tri_month == 4) {
                  this.triApr = member.tri_amount;
                } else if (member.tri_month == 5) {
                  this.triMay = member.tri_amount;
                } else if (member.tri_month == 6) {
                  this.triJun = member.tri_amount;
                } else if (member.tri_month == 7) {
                  this.triJul = member.tri_amount;
                } else if (member.tri_month == 8) {
                  this.triAug = member.tri_amount;
                } else if (member.tri_month == 9) {
                  this.triSep = member.tri_amount;
                } else if (member.tri_month == 10) {
                  this.triOct = member.tri_amount;
                } else if (member.tri_month == 11) {
                  this.triNov = member.tri_amount;
                } else if (member.tri_month == 12) {
                  this.triDec = member.tri_amount;
                }
              }
            }
          }
          this.tableData = doc2;

          let doc4 = [];
          let doc3 = {};
          doc3 = {
            triJan: this.triJan,
            triFeb: this.triFeb,
            triMar: this.triMar,
            triApr: this.triApr,
            triMay: this.triMay,
            triJun: this.triJun,
            triJul: this.triJul,
            triAug: this.triAug,
            triSep: this.triSep,
            triOct: this.triOct,
            triNov: this.triNov,
            triDec: this.triDec,
          };
          doc4.push(doc3);

          this.tableData3 = doc4;
        });
    },

    education() {
      this.showEducationModal = true; //모달창을 뜨게 해준다.
      let doc2 = [];
      if (this.query.eduDate == "") {
        this.query.eduDate = this.presentYear;
      }

      for (const member of this.data.education_detail) {
        const tempYear = member.edu_date.split("-");
        if (this.query.eduDate == this.presentYear) {
          if (tempYear[0] == this.presentYear) {
            const doc = {
              eduType: member.edu_type,
              eduDate: member.edu_date,
              eduDetail: member.edu_detail,
              eduSubject: member.edu_subject,
              eduCnt: member.edu_cnt,
              eduAtt: member.edu_att,
              eduGraduatedYn: member.edu_graduated_yn,
            };
            doc2.push(doc);
          }
        } else if (this.query.eduDate == this.lastYear) {
          if (tempYear[0] == this.lastYear) {
            const doc = {
              eduType: member.edu_type,
              eduDate: member.edu_date,
              eduDetail: member.edu_detail,
              eduSubject: member.edu_subject,
              eduCnt: member.edu_cnt,
              eduAtt: member.edu_att,
              eduGraduatedYn: member.edu_graduated_yn,
            };
            doc2.push(doc);
          }
        }
      }
      this.tableData = doc2;
    },

    onClose() {
      this.showDharmaModal = false;
      this.showAssociationModal = false;
      this.showPracticeModal = false;
      this.showTrinatnaModal = false;
      this.showQualificationModal = false;
      this.showServiceModal = false;
      this.showEducationModal = false;
    },
    srvTime() {
      var xmlHttp;

      if (window.XMLHttpRequest) {
        xmlHttp = new XMLHttpRequest(); // IE 7.0 이상, 크롬, 파이어폭스 등

        xmlHttp.open("HEAD", window.location.href.toString(), false);

        xmlHttp.setRequestHeader("Content-Type", "text/html");

        xmlHttp.send("");

        return xmlHttp.getResponseHeader("Date");
      }
    },
  },
};
</script>
<style scoped>
.title {
  color: #ea6010;
  font-family: Noto Sans;
}
.text {
  color: #000000;
  font-family: Noto Sans;
  padding-bottom: 10px;
}
.text-day {
  color: #413e3e;
  font-family: Noto Sans;
  font-size: 13px;
  padding-bottom: 10px;
  text-align: right;
}
.align-right {
  text-align: right;
}
body {
  background-attachment: fixed;
  background-image: url("/img/myjungto/bg-rectangle.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.table1 {
  width: 500px;
  border-bottom: 1px solid #b9b0b0;
  border-top: 1px solid #b9b0b0;
  border-collapse: collapse;
  text-align: center;
}
.table1 tr {
  border-bottom: 1px solid #b9b0b0;
  border-collapse: collapse;
}
.modalTypeTable .modal-body {
  padding-top: 0;
}
.xxx {
  border: 1px solid blue;
}
.left {
  width: 20%;
  padding: 3px;
  border-bottom: 1px solid rgb(242, 233, 233);
}
.right {
  width: 80%;
  border-bottom: 1px solid rgb(242, 233, 233);
}

.divLeft {
  width: 60%;
  text-align: left;
}
.divRight {
  width: 40%;
  text-align: left;
}
</style>
