<!-- 내정보 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card outer">
        <div class="card-header">
          <div class="row align-items-left">
            <div class="col center">
              <h3 class="mb-0 title">개인 정보</h3>
            </div>
          </div>
        </div>
        <div>
          <modal v-model:show="addressModal" size="md">
            <template v-slot:header>
              <h6 class="modal-title pl-0">주소 수정 요청</h6>
            </template>
            <form role="form">
              <h6 class="modal-title text-red">
                ** 검색을 이용하여 선택한 후에 상세주소를 입력하세요!!
              </h6>

              <div class="address">
                <input
                  type="text"
                  class="left mb-2 mt-3"
                  disabled
                  size="30"
                  id="sample3_postcode"
                  placeholder="우편번호"
                />
                <br />
                <input
                  type="text"
                  class="left mb-2"
                  size="30"
                  disabled
                  id="sample3_address"
                  placeholder="주소"
                /><br />
                <input
                  type="text"
                  class="left mb-2"
                  disabled
                  size="30"
                  id="sample3_extraAddress"
                  placeholder="참고항목"
                />
                <input
                  type="text"
                  class="left mb-2"
                  size="30"
                  id="sample3_detailAddress"
                  placeholder="상세주소(필수)"
                /><br />

                <div
                  id="wrap"
                  style="
                    border: 1px solid;
                    width: 300px;
                    height: 300px;
                    margin: 5px 0;
                    position: relative;
                  "
                ></div>
                <div id="searchButton">
                  <base-button
                    size="sm"
                    type="primary"
                    class="my-4"
                    @click="search"
                    >search</base-button
                  >
                </div>
              </div>

              <div class="form-group mt-2">
                <textarea
                  placeholder="  **사유(필수) 주소 수정은 위에 검색을 통해서 입력을 합니다. 주소 수정 사유를 여기에 간단히 적으시면 됩니다(사유는 필수값입니다) 상세주소 필드가 없는 분은 주택 또는 아파트로 기입 부탁 드립니다."
                  v-model="question"
                  cols="32"
                  rows="6"
                ></textarea>
              </div>

              <div class="inner">
                <base-button
                  type="primary"
                  class="my-4"
                  @click="onAddressEditDone"
                  >주소 수정 요청보내기</base-button
                >
                <base-button
                  type="secondary"
                  class="my-4"
                  @click="addressModal = false"
                  >닫 기</base-button
                >
              </div>
            </form>
          </modal>
        </div>
        <div>
          <modal v-model:show="teleModal" size="md">
            <template v-slot:header>
              <h6 class="modal-title pl-0">전화번호 수정 요청</h6>
            </template>
            <form role="form">
              <h6 class="modal-title"></h6>
              <div class="row ml-0">
                <input
                  type="text"
                  size="30"
                  class="left mb-2 mt-3 pr-0"
                  v-model="phoneNumber"
                  placeholder="전화번호 (010-0000-0000)"
                />
              </div>

              <div class="form-group mt-2">
                <textarea
                  placeholder="  **사유(필수) 전화번호 수정 사유를 여기에 간단히 적으시면 됩니다(사유는 필수값입니다)"
                  v-model="question"
                  cols="32"
                  rows="6"
                ></textarea>
              </div>

              <div class="inner">
                <base-button type="primary" class="my-4" @click="onTeleEditDone"
                  >전화번호 수정 요청보내기</base-button
                >
                <base-button
                  type="secondary"
                  class="my-4"
                  @click="teleModal = false"
                  >닫 기</base-button
                >
              </div>
            </form>
          </modal>
        </div>
        <div class="card-body inner">
          <ul class="list-group list-group-flush list my--3">
            <li class="list-group-item px-0">
              <div class="row justify-content-center">
                <img :src="data.mem_photo_url" width="300" />
              </div>
              <div class="row justify-content-center mt-5 mb-5">
                <!-- <qrcode-vue :value="QRValue" size="300" level="H" /> -->
                <base-button
                  size="ls"
                  outline
                  type="warning"
                  icon
                  @click.stop="go()"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-circle-08 text-warning"></i
                  ></span>
                  <span class="btn-inner--text">정토 온라인 출입증</span>
                </base-button>
              </div>

              <table align="center">
                <tr>
                  <td class="right" width="50px">전화:</td>
                  <td class="left">{{ data.mem_mobile }}</td>
                </tr>
                <tr>
                  <td class="right" width="50px">주소:</td>
                  <td class="left">{{ data.mem_address }}</td>
                </tr>
              </table>

              <div class="col mt-5 ml-2 center">
                <base-button
                  size="sm"
                  outline
                  type="primary"
                  icon
                  @click.stop="teleEdit()"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-tag text-primary"></i
                  ></span>
                  <span class="btn-inner--text">전화번호수정</span>
                </base-button>
                <base-button
                  size="sm"
                  outline
                  type="primary"
                  icon
                  @click.stop="addressEdit()"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-tag text-primary"></i
                  ></span>
                  <span class="btn-inner--text">주소수정</span>
                </base-button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import { Auth } from "@/api";
import Modal from "@/components/Modal";

export default {
  components: {
    RouteBreadCrumb,
    Modal,
  },
  data() {
    return {
      editModal: false,
      question: "",
      addressModal: false,
      teleModal: false,
      data: {},
      address: "",
      phoneNumber: "",
      memberChief: "",
      QRValue: null,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  computed: {},
  // 함수 추가
  methods: {
    // api 호출

    getDataFromApi() {
      this.modal = false;
      const token = Auth.tokenContent;
      api
        .get(`service/myinformation/personal?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const result = response.data;
          this.data = result.data;
        });
      api
        .get(`service/myinformation/member3?memberNo=${token.memberNo}`)
        .then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const result = response.data;
          let pass_id = result.data[0].pass_id;
          pass_id = 0;
          if (pass_id == 0) {
            api
              .put(`service/myinformation/member3?pass_id=${pass_id}`)
              .then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                pass_id = response.data.data;
                api
                  .post(`service/myinformation/member3?pass_id=${pass_id}`)
                  .then((response) => {
                    if (!api.isSuccess(response)) {
                      return;
                    }
                    this.url = response.data.data;
                  });
              });
          } else {
            api
              .post(`service/myinformation/member3?pass_id=${pass_id}`)
              .then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                this.url = response.data.data;
              });
          }
        });
    },

    go() {
      window.open(this.url);
    },

    // 수정 모달
    addressEdit() {
      this.question = "";
      document.getElementById("sample3_address").value = "";
      document.getElementById("sample3_detailAddress").value = "";
      document.getElementById("sample3_extraAddress").value = "";
      this.addressModal = true;
      var searchButton = document.getElementById("searchButton");
      searchButton.style.display = "none";
      this.search();
    },
    teleEdit() {
      this.question = "";
      this.teleModal = true;
    },

    // 주소 수정
    onAddressEditDone() {
      const url = `service/myinformation/manager?local=aa`;
      api.get(url).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;
        this.memberChief = "";
        for (const row of d2) {
          if (row.role === "지부회원담당") {
            this.memberChief = row.email;
          }
        }
        if (!document.getElementById("sample3_address").value) {
          alert("검색을 이용하여 주소를 입력하세요!!");
          return;
        }
        if (!document.getElementById("sample3_detailAddress").value) {
          alert(
            "상세주소가 비어있습니다. 상세주소가 없는분도 주택이나 아파트 간단히 기입바랍니다."
          );
          return;
        }
        if (!this.question) {
          alert("사유 항목이 비어있습니다.");
          return;
        }
        this.question = this.question.trim();
        if (document.getElementById("sample3_address").value) {
          this.question =
            "변경 요청 주소 : " +
            document.getElementById("sample3_address").value +
            " " +
            document.getElementById("sample3_detailAddress").value +
            document.getElementById("sample3_extraAddress").value +
            "\n" +
            this.question;
        }
        if (this.question.length < 2) {
          alert("사유 내용은 2글자 이상 작성하셔야 등록됩니다.");
          return;
        }
        const data = {};
        data.question = this.question;
        data.questPart = "주소수정";
        data.resulter = this.memberChief;
        api.post("service/myinformation/question", { data }).then(() => {
          this.$emit("addDone", data);
          this.addressModal = false;
        });
      });
    },
    // 전화번호 수정
    onTeleEditDone() {
      const url = `service/myinformation/manager?local=aa`;
      api.get(url).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;
        this.memberChief = "";
        for (const row of d2) {
          if (row.role === "지부회원담당") {
            this.memberChief = row.email;
          }
        }
        if (!this.phoneNumber) {
          alert("수정할 전화번호를 입력하세요!!");
          return;
        }
        this.question = this.question.trim();
        if (!this.question) {
          alert("사유 항목이 비어있습니다.");
          return;
        }

        if (this.phoneNumber) {
          this.question =
            "수정할 전화 번호 : " +
            this.phoneNumber +
            "\n" +
            "사유: " +
            this.question;
        }
        if (this.question.length < 2) {
          alert("문의 내용은 2글자 이상 작성하셔야 등록됩니다.");
          return;
        }
        const data = {};
        data.question = this.question;
        data.questPart = "전화번호수정";
        data.resulter = this.memberChief;
        api.post("service/myinformation/question", { data }).then(() => {
          this.$emit("addDone", data);
          this.teleModal = false;
        });
      });
    },
    search() {
      var element_wrap = document.getElementById("wrap");
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new window.daum.Postcode({
        oncomplete: function (data) {
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수
          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }
          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            var searchButton = document.getElementById("searchButton");
            searchButton.style.display = "block";

            // 조합된 참고항목을 해당 필드에 넣는다.
            document.getElementById("sample3_extraAddress").value = extraAddr;
          } else {
            document.getElementById("sample3_extraAddress").value = "";
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          document.getElementById("sample3_postcode").value = data.zonecode;
          document.getElementById("sample3_address").value = addr;
          this.address = addr;
          // 커서를 상세주소 필드로 이동한다.
          document.getElementById("sample3_detailAddress").focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          element_wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        onresize: function (size) {
          element_wrap.style.height = size.height + "px";
        },
        width: "100%",
        height: "100%",
      }).embed(element_wrap);

      // iframe을 넣은 element를 보이게 한다.
      element_wrap.style.display = "block";
      var searchButton = document.getElementById("searchButton");
      searchButton.style.display = "none";
    },
    foldDaumPostcode() {
      var element_wrap = document.getElementById("wrap");
      // iframe을 넣은 element를 안보이게 한다.
      element_wrap.style.display = "none";
    },
    showDaumPostcode() {
      var element_wrap = document.getElementById("wrap");
      // iframe을 넣은 element를 안보이게 한다.
      element_wrap.style.display = "block";
    },
  },
};
</script>
<style scoped>
.outer {
  text-align: left;
}
.center {
  text-align: center;
}

.inner {
  display: inline-block;
}
.left {
  text-align: left;
  padding-left: 10px;
}
.right {
  text-align: right;
}
</style>
