<template>
  <div class="wrapper">
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          v-if="isMemberService"
          :link="{
            name: '공지게시판',
            icon: 'ni ni-notification-70 text-warning',
            path: '/noticeService',
          }"
        />
        <sidebar-item
          v-if="isMemberService"
          :link="{
            name: '회원 종합 정보',
            icon: 'ni ni-shop text-primary',
            path: '/main2',
          }"
        />
        <ul class="navbar-nav ml-0" v-if="isResponsibility">
          <li class="nav-item" @click="voll1">
            <a class="nav-link" href="#">
              <i class="ni ni-circle-08 text-default" />
              <span class="nav-link-text">선거공보</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-0" v-if="isMemberService">
          <li class="nav-item" @click="voll2">
            <a class="nav-link" href="#">
              <i class="ni ni-circle-08 text-yellow" />
              <span class="nav-link-text">후보자추천</span>
            </a>
          </li>
        </ul>
        <sidebar-item
          v-if="isMemberService"
          :link="{
            name: '내 문의사항',
            icon: 'fa fa-question text-teal',
            path: '/question',
          }"
        />
        <sidebar-item
          v-if="isShare"
          :link="{
            name: '나누기',
            icon: 'ni ni-vector text-success',
            path: '/share',
          }"
        />
        <!-- <sidebar-item
          v-if="isMyJungto"
          :link="{
            name: '정토회일정(iframe)',
            icon: 'ni ni-calendar-grid-58 text-warning',
            path: '/schedule',
          }"
        /> -->
        <sidebar-item
          v-if="isMemberService"
          :link="{
            name: '정토회일정',
            icon: 'ni ni-calendar-grid-58 text-warning',
            path: '/schedule2',
          }"
        />
        <ul class="navbar-nav ml-4 mr-4" v-if="isMyJungto">
          <li class="nav-item">
            <hr class="my-3" />
          </li>
        </ul>
        <sidebar-item
          v-if="isManager"
          :link="{
            name: '문의사항관리',
            icon: 'fa fa-university text-green',
            path: '/questionManage',
          }"
        />
        <sidebar-item
          v-if="isNotice"
          :link="{
            name: '공지게시판관리',
            icon: 'ni ni-notification-70 text-teal',
            path: '/noticeManage',
          }"
        />
        <sidebar-item
          v-if="isManager"
          :link="{
            name: '관리자',
            icon: 'ni ni-settings text-orange',
            path: '/manager',
          }"
        />
        <sidebar-item
          v-if="isJeon"
          :link="{
            name: '교육생목록',
            icon: 'ni ni-circle-08 text-primary',
            path: '/eduStudent',
          }"
        />
        <sidebar-item
          :link="{
            name: '회관방문자 초대',
            icon: 'ni ni-bullet-list-67 text-warning',
            path: '/entry',
          }"
        />
        <sidebar-item
          v-if="isMaster"
          :link="{
            name: '회관방문자 초대관리',
            icon: 'ni ni-bullet-list-67 text-warning',
            path: '/entryAdmin',
          }"
        />
        <!-- <sidebar-item
          v-if="isJeon"
          :link="{
            name: '접속통계',
            icon: 'ni ni-chart-bar-32 text-teal',
            path: '/statics',
          }"
        /> -->
      </template>
      <template v-slot:links-after>
        <hr class="my-3" />
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="/#/my-info">
              <i class="ni ni-single-02 text-dark"></i>
              <span class="nav-link-text">개인정보</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logout">
              <i class="ni ni-user-run text-gray"></i>
              <span class="nav-link-text">로그아웃</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Auth } from "@/api";
import * as api from "@/api";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      isManager: false,
      isJeon: false,
      isShare: false,
      isMyJungto: true,
      isNotice: false,
      isMemberService: false,
      isResponsibility: false,
      isMaster: false,
    };
  },
  methods: {
    voll1() {
      const token = Auth.tokenContent;
      window.open("https://vote.jungto.org/" + token.memberNo);
    },
    voll2() {
      const token = Auth.tokenContent;
      window.open("https://vote2.jungto.org/" + token.memberNo);
    },
    getDataFromApi() {
      const token = Auth.tokenContent;
      let grade = token.grade;
      if (grade == "전법회원") {
        this.isMemberService = true;
      }
      if (token.voltype == "311010") {
        this.isResponsibility = true;
      } else if (token.voltype == "311020") {
        this.isResponsibility = false;
      }
      if (
        token.memberNo == 117530 ||
        token.memberNo == 142316 ||
        token.memberNo == 142260 ||
        token.memberNo == 41172 ||
        token.memberNo == 111946 ||
        token.memberNo == 72373 ||
        token.memberNo == 66606
      ) {
        this.isMemberService = true;
        this.isResponsibility = true;
      }
      if (token.memberNo == 117530) {
        this.isMaster = true;
      }
      const url = `service/myinformation/manager?memberNo=${token.memberNo}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        if (data.rows > 0) {
          // 내정보 관리자
          if (data.data[0].role != "") {
            this.isManager = true;
          }
          // 나누기 관리자
          if (data.data[0].shareRole == "관리자") {
            this.isShare = true;
            this.isJeon = true;
            sessionStorage.setItem("shareRole2", "manager");
          }
          if (data.data[0].noticeRole == "관리자") {
            this.isNotice = true;
          }
        }

        const url2 = `service/myinformation/edu?email=${token.email}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;

          const groupChief = [];
          const eduStudent = [];
          const mentorGroup = [];
          for (const member of data2.data) {
            for (const student of member.groups) {
              if (groupChief.indexOf(student.gr_mem_id) < 0) {
                groupChief.push(student.gr_mem_id);
              }
              if (mentorGroup.indexOf(student.dt_mem_id) < 0) {
                mentorGroup.push(student.dt_mem_id);
              }
              for (const item of student.students) {
                if (!eduStudent.includes(item)) {
                  eduStudent.push(item);
                }
              }
            }
          }
          if (eduStudent.includes(token.memberNo)) {
            this.isShare = true;
            this.isJeon = false;
            if (token.local.includes("국제")) {
              this.isMyJungto = true;
            } else {
              this.isMyJungto = false;
            }
            this.isManager = false;
            this.isNotice = false;
            sessionStorage.setItem("shareRole", "student");
            const url3 = `service/myinformation/eduStudent?memberNo=${token.memberNo}`;
            api.get(url3).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data;
              sessionStorage.setItem("eduOrder", data2.data[0].eduOrder);
              if (data.data[0].role != "") {
                this.isManager = true;
              }
              // 나누기 관리자
              if (data.data[0].shareRole == "관리자") {
                this.isShare = true;
                this.isJeon = true;
                sessionStorage.setItem("shareRole2", "manager");
              }
              if (data.data[0].noticeRole == "관리자") {
                this.isNotice = true;
              }
              if (grade === "전법회원") {
                this.isMyJungto = true;
                this.isMemberService = true;
              }
            });
          } else if (mentorGroup.includes(token.memberNo)) {
            this.isShare = true;
            this.isJeon = true;
            sessionStorage.setItem("shareRole", "mentor");
          } else if (groupChief.includes(token.memberNo)) {
            this.isShare = true;
            this.isJeon = true;
            sessionStorage.setItem("shareRole", "groupChief");
          }
        });
      });
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        Auth.logout(this.$router);
      } else {
        return;
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  created() {
    this.getDataFromApi();
  },
};
</script>
