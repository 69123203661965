<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">공지 {{ modalModeText }}</h6>
    </template>
    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row-sm-3 mb-3">
          <preset-radio
            v-model="newData.all"
            @change="preset"
            :choices="[
              ['상시', '상시'],
              ['기간', '기간'],
            ]"
          />
        </div>
        <div v-if="all">
          <base-input
            name="startDate"
            type="datetime-local"
            v-model="newData.startDate"
          >
            <template v-slot:addonLeft> 시작날짜</template>
          </base-input>
          <base-input
            name="endDate"
            type="datetime-local"
            v-model="newData.endDate"
          >
            <template v-slot:addonLeft> 종료날짜</template>
          </base-input>
        </div>
        <div v-else>
          <base-input
            name="datetime-local"
            disabled
            type="datetime-local"
            v-model="newData.startDate"
          >
            <template v-slot:addonLeft> 시작날짜</template>
          </base-input>
          <base-input
            name="endDate"
            disabled
            type="datetime-local"
            v-model="newData.endDate"
          >
            <template v-slot:addonLeft> 종료날짜</template>
          </base-input>
        </div>
        <base-input name="sendTeam" type="text" v-model="newData.sendTeam">
          <template v-slot:addonLeft> 발신처</template>
        </base-input>
        <div class="mb-4">
          <preset-select
            placeholder="수신처1 선택"
            v-model="newData.grade"
            @change="preset2"
            :choices="[
              ['전체회원', '전체회원'],
              ['일반회원', '일반회원'],
              ['전법회원', '전법회원'],
            ]"
          />
        </div>
        <div class="mb-4" v-if="disable">
          <preset-select
            placeholder="수신처2 선택(다중선택 가능)"
            multiple
            v-model="newData.receivedTeam"
            :choices="[
              ['모둠장', '모둠장'],
              ['지회장', '지회장'],
              ['지부장', '지부장'],
            ]"
          />
        </div>
        <div class="mb-4" v-else>
          <preset-select
            placeholder="수신처2 선택(다중선택 가능)"
            multiple
            disabled
            v-model="newData.receivedTeam"
            :choices="[
              ['모둠장', '모둠장'],
              ['지회장', '지회장'],
              ['지부장', '지부장'],
            ]"
          />
        </div>
        <div class="mb-4">
          <preset-select
            placeholder="수신처3 지부선택(다중선택 가능)"
            multiple
            v-model="newData.receivedLocal"
            :choices="[
              ['', '전체'],
              ['서울제주지부', '서울제주지부'],
              ['강원경기동부지부', '강원경기동부지부'],
              ['인천경기서부지부', '인천경기서부지부'],
              ['대전충청지부', '대전충청지부'],
              ['광주전라지부', '광주전라지부'],
              ['대구경북지부', '대구경북지부'],
              ['부산울산지부', '부산울산지부'],
              ['경남지부', '경남지부'],
              ['해외지부', '해외지부'],
              ['국제특별지부', '국제특별지부'],
              ['청년특별지부', '청년특별지부'],
              ['공동체특별지부', '공동체특별지부'],
              ['행복운동특별본부', '행복운동특별본부'],
            ]"
          />
        </div>
        <base-input name="title" type="text" v-model="newData.title">
          <template v-slot:addonLeft> 공지제목</template>
        </base-input>

        <div class="row-sm-3 mb-3">
          <preset-radio
            v-model="newData.isComment"
            @change="preset"
            :choices="[
              ['1', '댓글사용'],
              ['0', '안함'],
            ]"
          />
        </div>

        <div class="row">
          <label class="form-control-label">공지내용</label>
        </div>
        <div :key="htmlEditorKey">
          <html-editor v-model="newData.content" />
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import PresetRadio from "@/components/Inputs/PresetRadio";
import PresetSelect from "@/components/Inputs/PresetSelect";
import HtmlEditor from "@/components/Inputs/HtmlEditor.vue";

export default {
  components: {
    Modal,
    PresetRadio,
    PresetSelect,
    HtmlEditor,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        grade: "",
        receivedTeam: "",
        id: 0,
      },
      htmlEditorKey: 0,
      all: true,
      disable: true,
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    preset2() {
      if (
        this.newData.grade === "일반회원" ||
        this.newData.grade === "전체회원"
      ) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    preset() {
      if (this.newData.all === "상시") {
        this.all = false;
      } else {
        this.all = true;
      }
    },
    // 등록하기
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));
      if (data.sendTeam == "") {
        alert("발신처를 입력해주세요");
        return;
      }
      if (data.receivedTeam == "" && data.grade == "") {
        alert("하나 이상의 수신처를 선택해야됩니다.");
        return;
      }
      if (data.title == "") {
        alert("제목를 입력해주세요");
        return;
      }
      if (data.content == "") {
        alert("내용를 입력해주세요");
        return;
      }
      if (data.grade === "일반회원") {
        data.receivedTeam = ["일반회원"];
      } else if (data.grade === "전법회원") {
        if (data.receivedTeam.length === 0) {
          data.receivedTeam = ["전법회원"];
        }
      } else if (data.grade === "전체회원") {
        data.receivedTeam = ["전체회원"];
      }
      api.post("service/myinformation/noticeManage", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      if (data.all === "상시") {
        data.startDate = "";
        data.endDate = "";
      }
      if (data.grade === "일반회원") {
        data.receivedTeam = ["일반회원"];
      } else if (data.grade === "전법회원") {
        if (data.receivedTeam.length === 0) {
          data.receivedTeam = ["전법회원"];
        }
      } else if (data.grade === "전체회원") {
        data.receivedTeam = ["전체회원"];
      }
      api
        .put(`service/myinformation/noticeManage/${row.id}`, { data })
        .then(() => {
          this.$emit("editDone", row, data);
          this.hideModal();
        });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.all = "기간";
      this.newData.startDate = "";
      this.newData.endDate = "";
      this.newData.title = "";
      this.newData.isComment = 1;
      this.newData.content = "";
      this.newData.sendTeam = "";
      this.newData.receivedTeam = "";
      this.newData.grade = "";
      this.htmlEditorKey++;
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(
        `service/myinformation/noticeManage/${row.id}`,
        row,
        (data) => {
          if (data.all === "상시") {
            this.all = false;
          } else {
            this.all = true;
          }
          if (data.receivedTeam.includes("일반회원")) {
            data.grade = "일반회원";
            this.disable = false;
            data.receivedTeam = [];
          } else if (data.receivedTeam.includes("전체회원")) {
            data.grade = "전체회원";
            this.disable = false;
            data.receivedTeam = [];
          } else if (data.receivedTeam.includes("전법회원")) {
            data.grade = "전법회원";
            this.disable = true;
            data.receivedTeam = [];
          } else {
            data.grade = "전법회원";
            this.disable = true;
          }
          this.htmlEditorKey++;
          return {
            id: data.id,
            content: data.content,
            startDate: data.startDate,
            endDate: data.endDate,
            title: data.title,
            all: data.all,
            sendTeam: data.sendTeam,
            isComment: data.isComment,
            grade: data.grade,
            receivedTeam: data.receivedTeam,
          };
        }
      );
    },
  },
};
</script>
